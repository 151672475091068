import { ChangeEvent, useEffect, useRef } from "react";
import BoxIcons from "../icons/BoxIcons";
import ProgressBar from "../progressBar/ProgressBar";

interface BaseFileUploadInterface {
    accept?: string,
    className?: string,
    name: string,
    setNameFile: (name: string) => void
    percentLoad: number
}

interface FileUploadInterface extends BaseFileUploadInterface {
    file: File | undefined,
    handleFileChange: (e: ChangeEvent<HTMLInputElement>) => void,
    handleResetFileUpload: () => void,
}


interface FilesUploadInterface extends BaseFileUploadInterface {
    files: File[] | undefined,
    handleFilesChange: (e: ChangeEvent<HTMLInputElement>) => void,
    handleResetFileUpload: (index: number) => void,
}

const calculateSizeFile = (size: number) => {
    const realSize = size / 1000 / 1000;
    if (realSize < 1) {
        return (size / 1000).toFixed(1) + ' Ko';
    }
    return realSize.toFixed(1) + ' Mo';
}

const percentBgClassName = (percent: number): string => {
    if (percent <= 25) return 'bg-warning'
    else if (percent > 25 && percent <= 75) return 'bg-primary'
    else return 'bg-success'
}

export function FileUpload({ accept = '', file, className = 'p-4', name, percentLoad, handleFileChange, setNameFile, handleResetFileUpload }: FileUploadInterface) {
    const refInput = useRef<HTMLInputElement>(null);

    const handleClickInput = () => {
        refInput.current?.click();
    }

    useEffect(() => {
        setNameFile(name)
    }, [])

    return (
        <>
            <div className="text-center relative file-upload">
                <div className="border border-dotted relative hover:cursor-pointer" onClick={handleClickInput}>
                    <div className="upload absolute inset-0 bg-light flex items-center justify-center z-1">
                        <span>Cliquer pour charger un fichier</span>
                    </div>
                    <input ref={refInput} accept={accept} onChange={handleFileChange} id={name} type="file" name={name} className="p-4 w-full" />
                </div>
                {
                    file && (
                        <>
                            <div className="absolute inset-0 w-ful h-full bg-light preview top-0">
                                <div className="border mb-2 h-full px-4">
                                    <div className="flex justify-between items-center h-full">
                                        <div className="mr-2">
                                            <BoxIcons icon={'image-alt'} className="text-primary" />
                                        </div>
                                        <div className="overflow-hidden mr-auto">
                                            <h5 className="font-size-13 text-truncate mb-1">{file.name}</h5>
                                        </div>
                                        <div className="ml-2">
                                            <p className="text-muted">{calculateSizeFile(file.size)}</p>
                                        </div>
                                        <div className="ml-2">
                                            <button onClick={handleResetFileUpload} className="text-muted p-2 border-red-500 rounded-full"><BoxIcons icon="x" className="text-red-500" /></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                }
            </div>
            {percentLoad > 0 && <ProgressBar value={percentLoad} striped={true} className={percentBgClassName(percentLoad)} />}
        </>
    )
}

export function FilesUpload({ accept = '', files, className = 'p-4', name, percentLoad, handleFilesChange, setNameFile, handleResetFileUpload }: FilesUploadInterface) {
    const refInput = useRef<HTMLInputElement>(null);


    const handleClickInput = () => {
        refInput.current?.click();
    }

    useEffect(() => {
        setNameFile(name)
    }, [])

    return (
        <>
            <div className="text-center relative file-upload">
                <div className="border border-dotted relative hover:cursor-pointer" onClick={handleClickInput}>
                    <div className="upload absolute inset-0 bg-light flex items-center justify-center">
                        <span>Cliquer pour charger un ou plusieurs fichiers</span>
                    </div>
                    <input ref={refInput} accept={accept} multiple onChange={handleFilesChange} id={name} type="file" name={name} className="p-4 w-full" />
                </div>
            </div>
            {percentLoad > 0 && <ProgressBar value={percentLoad} striped={true} className={percentBgClassName(percentLoad)} />}
            <div className="mt-4 w-full">
                {
                    files?.map((file: File, index: number) => (
                        <div key={`file-upload-list-${index}`} className="w-ful h-full ">
                            <div className="border mb-2 h-full px-4 ">
                                <div className="flex justify-between items-center h-full">
                                    <div className="mr-2">
                                        <BoxIcons icon={'image-alt'} className="text-primary" />
                                    </div>
                                    <div className="overflow-hidden mr-auto">
                                        <h5 className="font-size-13 text-truncate mb-1">{file.name}</h5>
                                    </div>
                                    <div className="ml-2">
                                        <p className="text-muted">{calculateSizeFile(file.size)}</p>
                                    </div>
                                    <div className="ml-2">
                                        <button onClick={() => handleResetFileUpload(index)} className="text-muted p-2 border-red-500 rounded-full"><BoxIcons icon="x" className="text-red-500" /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </>
    )
}
