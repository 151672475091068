import { Route, Routes } from "react-router-dom";
import SignUp from "./pages/auth/SignUp";
import MainAuth from "./components/layout/MainAuth";
import { QueryClient, QueryClientProvider } from "react-query";
import SignIn from "./pages/auth/SignIn";
import AuthProvider from "./context/auth/AuthProvider";
import RequireAuth from "./components/RequireAuth";
import ManageRoute from "./components/ManageRoute";
import Main from "./components/layout/Main";
import SignWithCode from "./pages/auth/SignWithCode";
import SignOut from "./pages/auth/SignOut";
import ServerError from "./pages/error/500";
import LayoutProvider from "./context/layout/LayoutProvider";
import OperatorPage from "./pages/OperatorPage";
import CountryPage from "./pages/CountryPage";
import UserPage from "./pages/UserPage";
import SessionPage from "./pages/SessionPage";
import SenderPage from "./pages/SenderPage";
import MessagePage from "./pages/message/MessagePage";
import ServicePage from "./pages/ServicePage";
import RequireGuest from "./components/RequireGuest";
import RechargePage from "./pages/RechargePage";
import RolePage from "./pages/RolePage";
import RequireRole from "./components/RequireRole";
import Unautorized from "./pages/error/403";
import { UserRoles } from "./config/roles";
import DashboardPage from "./pages/DashboardPage";
import ProfilePage from "./pages/profile/ProfilePage";
import ProfileInformationPage from "./pages/profile/ProfileInformationPage";
import ProfileHostPage from "./pages/profile/ProfileHostPage";
import ProfileResetPasswordPage from "./pages/profile/ProfileResetPasswordPage";
import ProfileSenderPage from "./pages/profile/ProfileSenderPage";

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchIntervalInBackground: false,
        refetchOnWindowFocus: false
      },
    },
  });
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <LayoutProvider>
          <Routes>
            <Route element={<RequireGuest />}>
              <Route element={<MainAuth />}>
                <Route path={"sign-up"} element={<SignUp />} />
                <Route path={"sign-in"} element={<SignIn />} />
              </Route>
            </Route>
            <Route element={<RequireAuth />}>
              <Route element={<Main />}>
                <Route
                  element={
                    <RequireRole roles={[UserRoles.ADMIN, UserRoles.SUPPORT]} />
                  }
                >
                  <Route path={"user"} element={<UserPage />} />
                  <Route element={<RequireRole roles={[UserRoles.ADMIN]} />}>
                    <Route path={"session"} element={<SessionPage />} />
                    <Route path={"country"} element={<CountryPage />} />
                    <Route path={"operator"} element={<OperatorPage />} />
                    <Route path={"role"} element={<RolePage />} />
                  </Route>
                  <Route path={"sender"} element={<SenderPage />} />
                </Route>
                <Route path={"profile"} element={<ProfilePage />}>
                  <Route index element={<ProfileInformationPage />} />
                  <Route path={"hosts"} element={<ProfileHostPage />} />
                  <Route
                    path={"reset-password"}
                    element={<ProfileResetPasswordPage />}
                  />
                  <Route element={<RequireRole roles={[UserRoles.USER]} />}>
                    <Route path={"sender"} element={<ProfileSenderPage />} />
                  </Route>
                </Route>
                <Route path={"dashboard"} element={<DashboardPage />} />
                <Route path={"message"} element={<MessagePage />} />
                <Route path={"recharge"} element={<RechargePage />} />
                <Route path={"service"} element={<ServicePage />} />
              </Route>
            </Route>
            <Route path="auth/login/:code" element={<SignWithCode />} />
            <Route path="logout" element={<SignOut />} />
            <Route path="500" element={<ServerError />} />
            <Route path="403" element={<Unautorized />} />
            <Route path="*" element={<ManageRoute />} />
          </Routes>
        </LayoutProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
