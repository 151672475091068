export const TRAFFICS = [
    {
        label: "LOCAL",
        value: "LOCAL"
    },
    {
        label: "TRANSACTIONAL",
        value: "TRANSACTIONAL"
    },
    {
        label: "INTERNATIONAL",
        value: "INTERNATIONAL"
    }
]