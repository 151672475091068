import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { AddButton } from "../components/button/CustomButton";
import CardTable from "../components/card/CardTable";
import { InputNumber, InputText } from "../components/form/Input";
import ModalForm from "../components/modal/ModalForm";
import { FIRST_PAGE_VALUE, PER_PAGE_VALUE } from "../config/global";
import { APP_API_URL } from "../utils/api";
import { useMutateWithInvalidateQueries } from "../utils/mutation";
import { useQueries } from "../utils/query";
import moment from "moment";
import { Select2 } from "../components/form/Select";
import { TRAFFICS } from "./message/configDatas";
import { CheckRole, UserRoles } from "../config/roles";
import { ColumnInterface } from "../components/table/typings";

export default function RechargePage() {
    const [pagination, setPagination] = useState({ page: FIRST_PAGE_VALUE, perPage: PER_PAGE_VALUE });
    const [openModal, setOpenModal] = useState(false);
    let queryKey = ['recharges', pagination.page, pagination.perPage];
    const isAdmin = CheckRole([UserRoles.ADMIN]);
    const isSupport = CheckRole([UserRoles.SUPPORT]);
    const {
        register, control, reset, handleSubmit, formState: {
            errors
        }
    } = useForm();

    const {
        data: recharges,
        isLoading
    } = useQueries(APP_API_URL + `recharges?pageNo=${pagination.page}&pageSize=${pagination.perPage}`, queryKey);

    const {
        data: users,
        refetch: refetchUsers,
    } = useQueries(APP_API_URL + `user-list`, ['users-list'], {
        enabled: false
    });

    const {
        data: countries,
        refetch: refetchCountries,
    } = useQueries(APP_API_URL + `country-list`, ['countries-list'], {
        enabled: false
    });

    const {
        mutateAsync: storeData,
        isLoading: storeDataIsLoading
    } = useMutateWithInvalidateQueries(APP_API_URL + `user-credit`, 'post', queryKey)


    //Functions
    const handleSubmitAddForm = (values: any) => {
        storeData({ uId: values.userId.id, credit: parseInt(values.credit), balanceType: values.balanceType.value, countryId: values.countryId.id, operation: "CREDIT", description: values.description }).then((response: any) => {
            if (response && response.data.status === "OK") {
                reset();
                toast.success('Balance rechargée');
                setOpenModal(false);
            } else {
                toast.error('Impossible de recharger la balance');
            }
        });
    };

    const handleCancelForm = () => {
        reset();
        setOpenModal(false);
    };

    const handleAddElement = () => {
        setOpenModal(true);
        reset();
    }

    useEffect(() => {
        if (isAdmin) {
            refetchCountries();
            refetchUsers();
        }
    }, [refetchCountries, refetchUsers, isAdmin])

    //Columns Table
    const columns: ColumnInterface[] = [
        {
            title: "Utilisateur",
            key: "user",
            render: (element: any) => <span>{element.user.username}</span>,
            isVisible: isAdmin || isSupport
        },
        {
            title: "Pays",
            key: "country",
            render: (element: any) => element.country.name
        },
        {
            title: "Ancienne balance",
            dataIndex: "oldCredit",
            key: "oldCredit",
        },
        {
            title: "Crédit",
            dataIndex: "credit",
            key: "credit",
        },
        {
            title: "Nouvelle balance",
            dataIndex: "newCredit",
            key: "newCredit",
        },
        {
            title: "Type",
            dataIndex: "balanceType",
            key: "balanceType",
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
        },
        {
            title: "Date",
            key: "createdAt",
            render: (element: any) => moment(element.createdAt).format('YYYY-MM-DD HH:mm:ss')
        },
    ];
    return (
        <>
            <CardTable extras={[((isAdmin || isSupport) && <AddButton key={"add"} onClick={handleAddElement} value="Nouvelle recharge" />)]} columns={columns} title={"Recharges"}
                loading={isLoading} datas={recharges?.data}
                pagination={pagination} setPagination={setPagination}
                tableClassName="text-left" />
            <ModalForm title={"Recharger un compte"} isLoading={storeDataIsLoading}
                onClose={handleCancelForm}
                onConfirm={handleSubmit(handleSubmitAddForm)}
                open={openModal}>
                <div className={"grid grid-cols-1 gap-4 sm:grid-cols-2"}>
                    <div className="col-span-2">
                        <Select2 control={control} placeholder={"Selectionner l'utilisateur"} label={"Utilisateurs"}
                            register={register} error={errors?.userId}
                            name={"userId"} textKeyItem={"username"} valueKeyItem={"id"} items={users?.data?.data} />
                    </div>
                    <div>
                        <Select2 control={control} placeholder={"Selectionner le pays"} label={"Pays"}
                            register={register} error={errors?.countryId}
                            name={"countryId"} textKeyItem={"name"} valueKeyItem={"id"} items={countries?.data?.data} />
                    </div>
                    <div>
                        <Select2 control={control} placeholder={"Selectionner le type"} label={"Type de balance"}
                            register={register} error={errors?.balanceType}
                            name={"balanceType"} textKeyItem={"label"} valueKeyItem={"value"} items={TRAFFICS} />
                    </div>
                    <div>
                        <InputNumber name={"credit"} label="Crédit" placeholder="Entrer le nombre de crédit" options={{
                            required: true,
                            min: 1
                        }} error={errors?.credit} register={register} />
                    </div>
                    <div>
                        <InputText placeholder={"Description"} label={"Description"} register={register} error={errors?.description}
                            name={"description"} />
                    </div>
                </div>
            </ModalForm>
        </>
    );
}
