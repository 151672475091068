import Card from "../../components/card/Card";
import { APP_API_URL } from "../../utils/api";
import { Form } from "../../components/form/Form";
import { useForm } from "react-hook-form";
import Row from "../../components/grid/Row";
import Col from "../../components/grid/Col";
import { InputEmail, InputText } from "../../components/form/Input";
import { useMutateWithInvalidateQueries } from "../../utils/mutation";
import { toast } from "react-toastify";
import { useAuth } from "../../hooks/useAuth";
import { SubmitButton } from "../../components/button/CustomButton";

interface ProfileInfosInterface {
    name: string, company: string, email: string, phone: string
}

export default function UserInfosPage() {
    const { user: auth, updateProfile } = useAuth();
    const { handleSubmit, register, formState: {
        errors
    } } = useForm<ProfileInfosInterface>();

    const {
        mutateAsync, isLoading
    } = useMutateWithInvalidateQueries(APP_API_URL + `user-update-profile`, 'put', ['user-info'])


    //Functions
    const handleSubmitForm = (datas: ProfileInfosInterface) => {
        console.log(datas)
        mutateAsync(datas).then((response: any) => {
            if (response && response.status === 200) {
                toast.success('Profil mise à jour');
                updateProfile({
                    ...auth.user,
                    ...datas
                })
            }
        });
    }

    return (
        <Card title="Information du compte">
            <Form loading={isLoading} onSubmit={handleSubmit(handleSubmitForm)} >
                <Row cols={1} gap={4}>
                    <Col>
                        <InputText name="name" label="Noms et prénoms" placeholder="Entrez vos noms et prénoms" register={register} error={errors?.name} defaultValue={auth.user.name} />
                    </Col>
                    <Col>
                        <InputText name="company" label="Entreprise" placeholder="Entrez le nom de l'entreprise" register={register} error={errors?.company} defaultValue={auth.user.company} />
                    </Col>
                    <Col>
                        <InputEmail name="email" label="Adresse email" placeholder="Entrez l'adresse email" register={register} error={errors?.email} defaultValue={auth.user.email} />
                    </Col>
                    <Col>
                        <InputText name="phone" label="Numéro de téléphone" placeholder="Entrez le numéro de téléphone" register={register} error={errors?.phone} defaultValue={auth.user.phone} />
                    </Col>
                </Row>
                <hr className="my-4" />
                <div className="mt-4 w-full">
                    <SubmitButton className="ml-auto" value={"Mettre à jour"} loading={isLoading} />
                </div>
            </Form>
        </Card>
    )
}