import { LangType, LayoutType } from "../config/global";
import  secureLocalStorage  from  "react-secure-storage";

const auth_key = "auth";
const layout_key = "layout";
const lang_key = "lang";

export const addSessionUser = (user: any) => {
    secureLocalStorage.setItem(auth_key, JSON.stringify(user))
}

export const getSessionUser = () => {
    return secureLocalStorage.getItem(auth_key) !== "undefined" && secureLocalStorage.getItem(auth_key) !== null ? JSON.parse(""+secureLocalStorage.getItem(auth_key) ?? "") : null
}

export const removeSessionUser = () => {
    secureLocalStorage.removeItem(auth_key)
}

export const getSessionLayout = (): LayoutType => {
    if (localStorage.getItem(layout_key)) {
        return localStorage.getItem(layout_key) === LayoutType.horizontal ? LayoutType.horizontal : LayoutType.vertical;
    } else {
        return LayoutType.horizontal;
    }
}

export const setSessionLayout = (layout: LayoutType) => {
    localStorage.setItem(layout_key, layout)
}


export const getSessionLang = (): LangType => {
    if (localStorage.getItem(lang_key)) {
        return localStorage.getItem(lang_key) === LangType.fr ? LangType.fr : LangType.en;
    } else {
        return LangType.fr;
    }
}

export const setSessionLang = (layout: LangType) => {
    localStorage.setItem(lang_key, layout)
}