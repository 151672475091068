import { PATTERN_EMAIL } from "../../utils/pattern";
import { useState } from "react";
import SimpleButton from "../button/SimpleButton";
import MDIcons from "../icons/MDIcons";
import Datepicker from "react-tailwindcss-datepicker";
import { InputPropsInterface, LabelPropsInterface } from "./typings";
import { Switch } from "@headlessui/react";
import { Controller } from "react-hook-form";

export function Label({ name, text, className }: LabelPropsInterface) {
    return (text ? <label htmlFor={name} className={className}>{text}</label> : <></>);
}

export function Input({
    name,
    register,
    options = { required: true },
    placeholder = '',
    error = '',
    type = 'text',
    defaultValue = '',
    className = 'form-control'
}: InputPropsInterface) {
    return (<>
        <input type={type} id={name}
            className={`${className} ${error && 'is-invalid'}`}
            placeholder={placeholder} defaultValue={defaultValue}
            {...register(name, options)} /></>);
}

export function InputText({
    name,
    label = '',
    register,
    placeholder = '',
    error = '',
    defaultValue = '',
    options = { required: true, message: "Erreur" }
}: InputPropsInterface) {
    return (<>
        <Label name={name} text={label} />
        <Input register={register} type={"text"}
            name={name}
            placeholder={placeholder}
            options={options}
            error={error} defaultValue={defaultValue} /></>);
}

export function InputEmail({
    name,
    label = '',
    register,
    labelClassName = '',
    placeholder = '',
    error = '',
    defaultValue = '',
    options = { required: true, pattern: { value: PATTERN_EMAIL, message: "Erreur" }, },
}: InputPropsInterface) {
    return (<>
        <Label name={name} text={label} className={labelClassName} />
        <Input register={register} type={"email"}
            name={name}
            placeholder={placeholder}
            options={options} defaultValue={defaultValue}
            error={error} /></>);
}

export function InputPassword({
    name,
    label = '',
    register,
    labelClassName = '',
    placeholder = '',
    error = '',
}: InputPropsInterface) {
    const [open, setOpen] = useState(false);
    return (<>
        <Label name={name} text={label} className={labelClassName} />
        <div className="input-group auth-pass-inputgroup">
            <Input register={register} type={open ? "text" : "password"}
                name={name}
                placeholder={placeholder}
                options={{ required: true }}
                error={error} />
            <SimpleButton className="btn btn-light" onClick={() => setOpen(!open)} type="button" id="password-addon">
                <MDIcons icon={`${open ? 'eye-outline' : 'eye-off-outline'}`}></MDIcons></SimpleButton>
        </div>
    </>);
}

export function InputCheckbox({
    name,
    register,
    label = '',
    className = '',
    labelClassName = 'form-check-label',
    options = { required: false }
}: InputPropsInterface) {
    return (<>
        <div className={`form-check ${className}`}>
            <Input register={register} type={"checkbox"}
                name={name} className={"form-check-input"}
                options={options} />
            <Label className={labelClassName} name={name} text={label} />
        </div>
    </>);
}

export function InputNumber({
    name,
    label = '',
    register,
    placeholder = '',
    error = '',
    defaultValue = '',
    options = { required: true, message: "Erreur" }
}: InputPropsInterface) {
    return (<>
        <Label name={name} text={label} />
        <Input register={register} type={"number"}
            name={name}
            placeholder={placeholder}
            options={options} defaultValue={defaultValue}
            error={error} /></>);
}

export function InputColor({
    name,
    label = '',
    register,
    placeholder = '',
    error = '',
    options = { required: true, message: "Erreur" }
}: InputPropsInterface) {
    return (<>
        <Label name={name} text={label} />
        <Input register={register} type={"color"}
            name={name}
            placeholder={placeholder}
            options={options}
            error={error} /></>);
}

export function InputDate({
    name,
    label = '',
    register,
    placeholder = '',
    error = '',
    options = { required: true, message: "Erreur" }
}: InputPropsInterface) {
    return (<>
        <Label name={name} text={label} />
        <Input register={register} type={"date"}
            name={name}
            placeholder={placeholder}
            options={options}
            error={error} /></>);
}


export function InputTime({
    name,
    label = '',
    register,
    placeholder = '',
    error = '',
    options = { required: true, message: "Erreur" }
}: InputPropsInterface) {
    return (<>
        <Label name={name} text={label} />
        <Input register={register} type={"time"}
            name={name}
            placeholder={placeholder}
            options={options}
            error={error} /></>);
}

export function InputFile({
    name,
    register,
    label = '',
    placeholder = '',
    error = '',
    options = { required: true, message: "Erreur" }
}: InputPropsInterface) {
    return (<>
        <Label name={name} text={label} />
        <Input register={register} type={"file"}
            name={name}
            placeholder={placeholder}
            options={options}
            error={error} /></>);
}


export function InputDatePicker({
    name,
    label = '',
    options = { required: true, message: "Erreur" }
}: InputPropsInterface) {
    return (<>
        <Label name={name} text={label} />
        <Datepicker
            i18n={"fr"}
            showShortcuts={true}
            onChange={() => {
            }}
            useRange={false} containerClassName={""}
            configs={{
                shortcuts: {
                    today: "Aujourd'hui",
                    yesterday: "Hier",
                    past: period => `Les ${period}  derniers jours`,
                    currentMonth: "Ce mois-ci",
                    pastMonth: "Le mois dernier"
                },
            }}
            value={{ startDate: null, endDate: null }} /></>);
}

export function InputUrl({
    name,
    label = '',
    register,
    placeholder = '',
    error = '',
    options = { required: true }
}: InputPropsInterface) {
    return (<>
        <Label name={name} text={label} />
        <Input register={register} type={"url"}
            name={name}
            placeholder={placeholder}
            options={options}
            error={error} /></>);
}

export function InputRadio({
    name,
    label = '',
    defaultValue = false,
    control,
    options = { required: false }
}: InputPropsInterface) {
    return (<>
        <Label name={name} text={label} />
        <Controller defaultValue={defaultValue} control={control} rules={options} render={({ field }) => {
            return (
                <Switch defaultChecked={defaultValue} checked={field.value} {...field}>
                    {({ checked }) => (
                        <span
                            className={`${checked ? 'bg-green-400' : 'bg-gray-300'} relative inline-flex h-[24px] w-[48px] items-center rounded-full`}
                        >
                            <span
                                className={`${checked ? 'translate-x-6' : 'translate-x-2'
                                    } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                            />
                        </span>
                    )}
                </Switch>);
        }} name={name} /> </>);
}

export function TextArea({
    name,
    label = '',
    register,
    placeholder = '',
    className = '',
    error = '',
    defaultValue = '',
    options = { required: true }
}: InputPropsInterface) {
    return (<>
        <Label name={name} text={label} />
        <textarea {...register(name, options)}
            id={name}
            className={`form-control ${className} ${error && 'is-invalid'}`}
            placeholder={placeholder} defaultValue={defaultValue} ></textarea></>);
}
