import { Disclosure, Transition } from "@headlessui/react";
import BoxIcons from "../icons/BoxIcons";

interface AccordionInterface{
    title: string,
    children: React.ReactNode,
    open?: boolean
}

export default function Accordion({title, children, open = false }: AccordionInterface) {
    return <>
        <Disclosure defaultOpen={open} as="div" className="mt-2" >
            {({ open }) => (
                <>
                    <Disclosure.Button className="flex w-full justify-between rounded-lg bg-light px-4 py-2 text-left text-sm font-medium">
                        <span>{title}</span>
                        <BoxIcons icon={"chevron-down"}
                            className={`${open ? 'rotate-[-180deg] transform' : ''
                                } h-5 w-5 transition-transform`}
                        />
                    </Disclosure.Button>
                    <Transition
                        enter="transition duration-100 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                    >
                        <Disclosure.Panel className="pt-4 pb-2 text-sm">
                            {children}
                        </Disclosure.Panel>
                    </Transition>
                </>
            )}
        </Disclosure>
    </>;
}