import { useNavigate } from "react-router-dom";
import { APP_API_URL, AUTH_API_URL } from "../../utils/api";
import { useAuth } from "../../hooks/useAuth";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { SubmitButton } from "../../components/button/CustomButton";
import { InputPassword, InputText } from "../../components/form/Input";
import { Form } from "../../components/form/Form";
import { useMutate } from "../../utils/mutation";
import profileImg from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.png";
import { HOME_PAGE } from "../../config/global";
import { useState } from "react";


function SignIn() {
    //Hooks
    const {
        register, handleSubmit, formState: {
            errors
        }
    } = useForm();
    const { mutateAsync: login, isLoading } = useMutate(AUTH_API_URL);
    const [userDataIsLoading, setUserDataIsLoading] = useState(false)
    const navigate = useNavigate();
    const auth = useAuth();

    //Functions
    const handleSubmitForm = (datas: any) => {
        let response = login(datas);
        response.then((r: any) => {
            if (r) {
                setUserDataIsLoading(true)
                const toast_id = toast.loading("Chargement des données en cours....");
                fetch(APP_API_URL + `user-info`, {
                    headers: {
                        "Authorization": "Bearer " + r.data.token,
                        "Content-type": "application/json"
                    },
                    method: "GET"
                }).then((response: any) => {
                    return response.json()
                }).then((response) => {
                    setUserDataIsLoading(false)
                    if (response.status === "OK") {
                        toast.update(toast_id, { render: "Authentification Réussie", type: "success", isLoading: false, autoClose: 3000 });
                        auth.signIn({
                            user: {
                                api_token: r.data.token,
                                username: datas.username,
                                api_key: response.data.apiKey,
                                email: response.data.email,
                                phone: response.data.phone,
                                name: response.data.name,
                                company: response.data.company
                            },
                            roles: response.data.role
                        })
                        navigate(HOME_PAGE, { replace: true });
                    }
                    else {
                        toast.update(toast_id, { render: "Une erreur est survenu lors du chargement des données!", type: "error", isLoading: false, autoClose: 3000 });
                    }
                })
            }else{
                setUserDataIsLoading(false)
                toast.error("Les informations de connexion sont incorrectes");
            }
        });
    }
    return (
        <>
            <div className="card shadow">
                <div className="bg-blue-500 bg-opacity-20">
                    <div className="flex">
                        <div className="w-3/5">
                            <div className="text-primary p-4">
                                <h5 className="text-primary mb-2 font-size-16">Welcome Back !</h5>
                                <p>Sign in to continue to NEXAH.</p>
                            </div>
                        </div>
                        <div className="w-2/5 align-self-end">
                            <img src={profileImg} alt="User profile" className="img-fluid bg-cover" />
                        </div>
                    </div>
                </div>
                <div className="card-body pt-0">
                    <div className="auth-logo sm:mt-[-20px] sm:justify-start grid mt-[-10px] justify-center">
                        <a href="https://nexah.net" className="auth-logo-dark">
                            <div className="avatar-md profile-user-wid mb-4">
                                <span
                                    className="avatar-title bg-gray-100 rounded-[50%] bg-light">
                                    <img src={logo} alt="Logo Nexah" className=" h-10 w-10" />
                                </span>
                            </div>
                        </a>
                    </div>
                    <div className="p-2">
                        <fieldset disabled={userDataIsLoading}>
                            <Form loading={isLoading} onSubmit={handleSubmit(handleSubmitForm)}>
                                <div className="mb-3">
                                    <InputText placeholder={"Entrer votre username"} label={"User Name"}
                                        labelClassName={"form-label inline-block"} register={register}
                                        name={"username"} error={errors.username} />
                                </div>
                                <div className="mb-3">
                                    <InputPassword register={register} name={"password"} label={"Mot de passe"}
                                        labelClassName={"form-label inline-block"}
                                        placeholder={"Enter votre mot de passe"}
                                        error={errors.password} />
                                </div>
                                <div className="mt-8 grid">
                                    <SubmitButton value={"Connexion"} loading={isLoading} />
                                </div>
                            </Form>
                        </fieldset>
                    </div>

                </div>
            </div>
        </>
    );
}

export default SignIn;
