interface BadgeInterface {
    active?: boolean,
    className?: string,
    text?: string
}
export function BadgeCircleCheck({ active, className = "" }: BadgeInterface) {
    return (<span className={`badge h-5 rounded-full ${className} ${active ? "bg-success" : "bg-danger"}`}></span>)
}
export function BadgeCircle({ className = "" }: BadgeInterface) {
    return (<span className={`badge rounded-full ${className}`}></span>)
}
export function BadgeRounded({ className = "", text = "" }: BadgeInterface) {
    return (<span className={`badge rounded-sm ${className}`}>{text}</span>)
}