import { LangType, LayoutType } from "../config/global";
import secureLocalStorage from "react-secure-storage";

const auth_key = "auth";
const layout_key = "layout";
const lang_key = "lang";

export const getSessionLang = (): LangType => {
    if (localStorage.getItem(lang_key)) {
        return localStorage.getItem(lang_key) === LangType.fr ? LangType.fr : LangType.en;
    } else {
        return LangType.fr;
    }
}

const addSessionUser = (user: any) => {
    secureLocalStorage.setItem(auth_key, JSON.stringify(user))
}

const getSessionUser = () => {
    return secureLocalStorage.getItem(auth_key) !== "undefined" && secureLocalStorage.getItem(auth_key) !== null ? JSON.parse("" + secureLocalStorage.getItem(auth_key) ?? "") : null
}

const removeSessionUser = () => {
    secureLocalStorage.removeItem(auth_key)
}

const getSessionLayout = (): LayoutType => {
    if (localStorage.getItem(layout_key)) {
        return localStorage.getItem(layout_key) === LayoutType.horizontal ? LayoutType.horizontal : LayoutType.vertical;
    } else {
        return LayoutType.horizontal;
    }
}

const setSessionLayout = (layout: LayoutType) => {
    localStorage.setItem(layout_key, layout)
}

const setSessionLang = (layout: LangType) => {
    localStorage.setItem(lang_key, layout)
}

export default function useStorage() {

    return {
        addSessionUser,
        getSessionUser,
        removeSessionUser,
        getSessionLayout,
        setSessionLayout,
        getSessionLang,
        setSessionLang
    }
}