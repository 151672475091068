import {useMutate} from "../../utils/mutation";
import {useNavigate, useParams} from "react-router-dom";
import {useAuth} from "../../hooks/useAuth";
import { toast } from "react-toastify";
import {APP_API_URL} from "../../utils/api";
import Loader from "../../components/loader/Loader";
import { useEffect } from "react";
import { HOME_PAGE } from "../../config/global";

export default function SignWithCode() {
    const navigate = useNavigate();
    const auth = useAuth();
    const {code} = useParams();
    
    //Hooks
    const {mutateAsync: login} = useMutate(APP_API_URL + 'login2');

    const loginCall = () => {
        const datas = {code: code};
        var result = login(datas);
        result.then((response:any) => {
            if (response.status === 200) {
                auth.signIn(response.data.data);
                navigate(HOME_PAGE, {replace: true});
                toast.success("Authentification réussi!");
            } else if (response.status === 500) {
                navigate("/500", {replace: true})
            }
        }).catch((e) => {
            navigate("/500", {replace: true})
        });
    }
    
    useEffect(() => {
        loginCall()
    }, [])

    return (
        <div className="grid h-screen place-items-center ">
            <Loader />
        </div>
    );
}
