import { useState } from "react";
import CardTable from "../components/card/CardTable";
import { FIRST_PAGE_VALUE, PER_PAGE_VALUE } from "../config/global";
import { APP_API_URL } from "../utils/api";
import { useQueries } from "../utils/query";
import moment from "moment";

export default function SessionPage() {
    const [pagination, setPagination] = useState({page: FIRST_PAGE_VALUE, perPage: PER_PAGE_VALUE});
    let queryKey = ['sessions', pagination.page, pagination.perPage];
    const {
        data: sessions,
        isLoading
    } = useQueries(APP_API_URL + `sessions?pageNo=${pagination.page}&pageSize=${pagination.perPage}`, queryKey);


    //Columns Table
    const columns = [

        {
            title: "Utilisateur",
            render: (element:any) => <span>{element.user.username}</span>,
            key: "username",
        },
        {
            title: "Nom",
            dataIndex:"name",
            key: "name",
        },
        {
            title: "Adresse IP",
            dataIndex: "ip",
            key: "ip",
        },
        {
            title: "Port",
            dataIndex: "port",
            key: "port",
        },
        {
            title: "Type de connexion",
            dataIndex: "bindType",
            key: "bindType",
        },
        {
            title: "Status",
            key: "open",
            render: (element: any) => <div className={`h-5 w-5 mx-auto rounded-full ${element.open?"bg-success":"bg-danger"}`}></div>
        },
        {
            title: "Date de création",
            key: "createdAt",
            render: (element: any) => <span>{element.createdAt ? moment(element.createdAt).format('YYYY-MM-DD HH:mm:ss') : "----"}</span> 
        },

    ];
    return (
        <>
            <CardTable  columns={columns} title={"Sessions"}
                       loading={isLoading} datas={sessions?.data}
                       pagination={pagination} setPagination={setPagination}
                       tableClassName="text-center"/>
        </>
    );
}
