import { CancelButton, SubmitButton } from "../button/CustomButton";
import SimpleModal from "./SimpleModal";
import { Form } from "../form/Form";
import { ModalPropsInterface } from "./typings";


function ModalForm({ title, open, isLoading = false, onClose, onConfirm, children }: ModalPropsInterface) {
    return (
        <SimpleModal title={title}
            open={open} onClose={onClose} footer={[
                <CancelButton key="cancel" onClick={onClose} />,
                <SubmitButton key="submit" loading={isLoading} onClick={onConfirm} />,
            ]}>
            <Form loading={isLoading} onSubmit={onConfirm}>
                {children}
            </Form>
        </SimpleModal>
    );
}

export default ModalForm
