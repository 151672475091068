import Swal from "sweetalert2";

export enum LayoutType {
    horizontal = "horizontal",
    vertical = "vertical"
}

export enum LangType {
    fr = "fr",
    en = "en"
}

export enum FormActionType {
    ADD = "add",
    EDIT = "edit",
    DETAIL = "detail"
}

export const swal = Swal.mixin({
    customClass: {
        confirmButton: 'mr-3 p-4 bg-soft-primary rounded',
        cancelButton: 'ml-2 p-4 bg-soft-danger rounded'
    },
    buttonsStyling: false
})

export const FIRST_PAGE_VALUE = 1;
export const PER_PAGE_VALUE = 25;
export const NUMBERS_OF_ROWS_LOADER = 5;
export const HOME_PAGE = '/message';
export const SSO_ENABLE = false;