import { CancelButton, DeleteButton } from "../button/CustomButton";
import SimpleModal from "./SimpleModal";
import { ModalPropsInterface } from "./typings"

export default function ModalDelete({ open, title = "SUPPRESSION", isLoading, onClose, onConfirm, children }: ModalPropsInterface) {
    return (
        <SimpleModal open={open} onClose={onClose} title={title}
            footer={[<CancelButton key="cancel" onClick={onClose} />,
            <DeleteButton key="delete" loading={isLoading} onClick={onConfirm} />]}>
            <p className={"py-2 text-center font-bold"}>{children}</p>
        </SimpleModal>
    );
}
