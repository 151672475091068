import { createContext } from "react";
import { AuthContextInterface, AuthUserDetailInterface } from "./typinds";

const AuthContext = createContext<AuthContextInterface>({
    user: {
        user: { name: "", username: "", api_key: "", phone: "", email: "", company: "", api_token: "" },
        roles: {id: 0, name: ""}
    },
    signIn: (function (user: AuthUserDetailInterface) { }),
    signOut: (function () { }),
    updateProfile: (function (datas: AuthUserDetailInterface) { })
});
export default AuthContext;