import { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutateWithInvalidateQueries } from "../../utils/mutation";
import { APP_API_URL } from "../../utils/api";
import { toast } from "react-toastify";
import ModalForm from "../../components/modal/ModalForm";
import { Select2Tag } from "../../components/form/Select";
import { AddButton } from "../../components/button/CustomButton";
import Card from "../../components/card/Card";
import Table from "../../components/table/Table";
import { ColumnInterface } from "../../components/table/typings";
import { useQueries } from "../../utils/query";
import { useAuth } from "../../hooks/useAuth";

export default function ProfileHostPage() {
    const { user: auth } = useAuth();
    const [openUpdateHostModal, setOpenUpdateHostModal] = useState(false);
    const {
        data,
        isLoading
    } = useQueries(APP_API_URL + `user-info`, ["user-info"]);

    const { handleSubmit: handleSubmitUpdateHost, register: registerUpdateHost, reset: resetUpdateHost, control: controlUpdateHost, formState: {
        errors: errorsUpdateHost
    } } = useForm();

    const {
        mutateAsync: updateHost,
        isLoading: updateHostIsLoading,
    } = useMutateWithInvalidateQueries(APP_API_URL + `user-whitelist-hosts`, 'post', ['user-info'])


    const handleSubmitUpdateHostForm = (values: any) => {
         let newHosts = values.hosts.map((h: any) => { return h.value }).toString()
        updateHost({ username: auth.user.username, hosts: newHosts }).then((response: any) => {
            if (response && response.status === 200) {
                resetUpdateHost();
                toast.success('Ip whitelisté avec succès');
                setOpenUpdateHostModal(false);
            }
        });
    };

    const handleCancelForm = () => {
        resetUpdateHost();
        setOpenUpdateHostModal(false);
    };


    const columnsIps: ColumnInterface[] = [
        {
            title: "#",
            key: "index",
            render: (element: any, index: number) => <>{index + 1}</>,
        },
        {
            title: "IP",
            key: "id",
            dataIndex: "ip"
        }
    ];

    return (
        <>
            <Card title="Listes des IPs whitelistés" extras={[<AddButton key={"add"} onClick={() => setOpenUpdateHostModal(true)} />]}>
                <div className="card-body">
                    <Table tableClassName="text-center" columns={columnsIps} datas={data?.data.data.hosts} loading={isLoading} />
                </div>
            </Card>
            <ModalForm title={"Ajouter une IP"} isLoading={updateHostIsLoading}
                onClose={handleCancelForm}
                onConfirm={handleSubmitUpdateHost(handleSubmitUpdateHostForm)}
                open={openUpdateHostModal} >
                <div>
                    <Select2Tag placeholder={"Entrer une adresse IP"} label={"Adresse IP"}
                        register={registerUpdateHost} control={controlUpdateHost}
                        error={errorsUpdateHost?.hosts} multiple
                        name={"hosts"} textKeyItem={"id"} valueKeyItem={"value"} />
                </div>
            </ModalForm>
        </>
    )

}