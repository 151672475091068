import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { ModalPropsInterface } from './typings'

export default function SimpleModal({ children, title, open = false, onClose, footer }: ModalPropsInterface) {
    return (
        <>
            <Transition appear show={open} as={Fragment}>
                <Dialog as="div" className="relative z-[99999]" onClose={onClose}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    className="w-full max-w-md transform overflow-hidden bg-white text-left align-middle shadow-xl transition-all">
                                    <div
                                        className="flex items-center justify-between py-2 px-4 border-b border-solid border-slate-200 rounded-t">
                                        <Dialog.Title
                                            as="h3"
                                            className="text-lg font-medium leading-6 text-gray-900"
                                        >
                                            {title}
                                        </Dialog.Title>
                                        <button
                                            className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                            onClick={onClose}
                                        >
                                            <span
                                                className="bg-transparent text-red-500 h-6 w-6 text-2xl block">
                                                <i className='bx bx-x'></i>
                                            </span>
                                        </button>
                                    </div>
                                    <div className="px-4 py-4 ">
                                        {children}
                                    </div>

                                    {footer && (<div
                                        className="flex gap-2 items-center justify-end p-3 border-t border-solid border-slate-200 rounded-b">
                                        {
                                            footer.map((foot) => {
                                                return (foot);
                                            })
                                        }
                                    </div>)}
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}
