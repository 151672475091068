import { useState } from "react";
import AuthContext from "./AuthContext"
import { AUTH_SIGN_IN_URL } from "../../utils/api";
import { addSessionUser, getSessionUser, removeSessionUser } from "../../utils/storage";
import { AuthUserDetailInterface, AuthUserInterface } from "./typinds";

interface AuthProviderPropsInterface {
    children: React.ReactNode
}

function AuthProvider({ children }: AuthProviderPropsInterface) {
    let [user, setUser] = useState<AuthUserInterface>(getSessionUser());
    let signIn = (user: any) => {
        setUser(user)
        addSessionUser(user)
    }
    let signOut = () => {
        removeSessionUser()
        window.location.assign(AUTH_SIGN_IN_URL)
    };
    let updateProfile = (datas: AuthUserDetailInterface) => {
        if (user) {
            setUser({
                user: datas,
                roles: user.roles
            })
            addSessionUser(user)
        }
    }
    let value = { user, signIn, signOut, updateProfile };
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export default AuthProvider;
