import {Outlet} from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import TopNav from "./TopNav";
import SideNav from "./SideNav";
import { useLayout } from "../../hooks/useLayout";

function Main() {
    const { layout } = useLayout();
    return (
        <>
            <div id="layout-wrapper">
                <Header />
                {
                    layout === "vertical" ? <SideNav /> : <TopNav />
                }
                <div className="main-content">
                    <div className="page-content overflow-y-auto">
                        <div className="px-4">
                            <Outlet />
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        </>);
}

export default Main;
