import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { EditButton, AddButton } from "../components/button/CustomButton";
import CardTable from "../components/card/CardTable";
import { InputNumber, InputText } from "../components/form/Input";
import ModalForm from "../components/modal/ModalForm";
import { FIRST_PAGE_VALUE, FormActionType, PER_PAGE_VALUE } from "../config/global";
import { APP_API_URL } from "../utils/api";
import { useMutateWithInvalidateQueries } from "../utils/mutation";
import { useQueries } from "../utils/query";

export default function CountryPage() {
    const [pagination, setPagination] = useState({ page: FIRST_PAGE_VALUE, perPage: PER_PAGE_VALUE });
    const [currentElement, setCurrentElement] = useState(0);
    const [titleForm, setTitleForm] = useState("Ajouter un pays");
    const [actionForm, setActionForm] = useState("add");
    const [openModal, setOpenModal] = useState(false);
    let queryKey = ['countries', pagination.page, pagination.perPage];
    const {
        register, setValue, reset, handleSubmit, formState: {
            errors
        }
    } = useForm();
    
    const {
        data: countries,
        isLoading
    } = useQueries(APP_API_URL + `countries?pageNo=${pagination.page}&pageSize=${pagination.perPage}`, queryKey);

    const {
        mutateAsync: storeData,
        isLoading: storeDataIsLoading
    } = useMutateWithInvalidateQueries(APP_API_URL + `country-store`, 'post', queryKey)

    const {
        mutateAsync: updateData,
        isLoading: updateIsLoading
    } = useMutateWithInvalidateQueries(APP_API_URL + `country/${currentElement}`, 'put', queryKey)

    //Functions
    const handleSubmitAddForm = (values: any) => {
        storeData(values).then(() => {
            reset();
            setOpenModal(false);
            toast.success('Pays ajouté');
        });
    };
    const handleSubmitEditForm = (values: any) => {
        updateData(values).then(() => {
            reset();
            setOpenModal(false);
            setCurrentElement(0);
            toast.success('Pays modifié');
        });
    };

    const handleCancelForm = () => {
        reset();
        setCurrentElement(0);
        setOpenModal(false);
    };

    const handleAddElement = () => {
        setTitleForm("Ajouter un Pays");
        setActionForm("add");
        setCurrentElement(0);
        setOpenModal(true);
        reset();
    }
    const handleEditElement = (element: any) => {
        setTitleForm("Modifier un Pays");
        setActionForm("edit");
        setValue('code', element.code)
        setValue('name', element.name)
        setValue('prefix', element.prefix)
        setValue('size', element.size)
        setCurrentElement(element.id)
        setOpenModal(true);
    }

    //Columns Table
    const columns = [
        {
            title: "Nom",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Code",
            dataIndex: "code",
            key: "code",
        },
        {
            title: "Préfix",
            dataIndex: "prefix",
            key: "prefix",
        },
        {
            title: "Taille",
            dataIndex: "size",
            key: "size",
        },
        {
            title: <span style={{ textAlign: "center" }}>#</span>,
            key: "actions",
            render: (element: any) => (
                <div className="flex justify-center gap-2">
                    <EditButton onClick={() => handleEditElement(element)} />
                </div>
            )
        }
    ];
    return (
        <>
            <CardTable extras={[<AddButton key={"add"} onClick={handleAddElement}>Ajouter</AddButton>]} columns={columns} title={"Pays"}
                loading={isLoading} datas={countries?.data}
                pagination={pagination} setPagination={setPagination}
                tableClassName="text-center" />
            <ModalForm title={titleForm} isLoading={actionForm === FormActionType.ADD ? storeDataIsLoading : updateIsLoading}
                onClose={handleCancelForm}
                onConfirm={handleSubmit(actionForm === FormActionType.ADD ? handleSubmitAddForm : handleSubmitEditForm)}
                open={openModal}>
                <div className={"grid grid-cols-1 gap-4 sm:grid-cols-2 "}>
                    <div>
                        <InputText placeholder={"Entrer le nom"} label={"Nom"} register={register} error={errors?.name}
                            name={"name"} />
                    </div>
                    <div>
                        <InputText placeholder={"Entrer le code"} label={"Code"} register={register} error={errors?.code}
                            name={"code"} />
                    </div>
                    <div>
                        <InputNumber placeholder={"Entrer le préfix"} label={"Préfix"} register={register} error={errors?.prefix}
                            name={"prefix"} />
                    </div>
                    <div>
                        <InputNumber placeholder={"Entrer la taille"} label={"Size"} register={register} error={errors?.size}
                            name={"size"} />
                    </div>
                </div>
            </ModalForm>
        </>
    );
}
