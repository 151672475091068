interface ProgressBarInterface {
    className?: string,
    striped?: boolean,
    value: number
}
function ProgressBar({ className, striped = false, value }: ProgressBarInterface) {
    return (
        <div className="progress">
            <div
                className={`progress-bar progress-bar-animated ${className} ${striped && "progress-bar-striped"}`}
                role="progressbar"
                style={{ width: `${value}%` }}
                aria-valuenow={value}
                aria-valuemin={0}
                aria-valuemax={100}
            />
        </div>
    );
}

export default ProgressBar;