import axios, { AxiosRequestConfig } from "axios";
import { toast } from "react-toastify";
import { getSessionUser, removeSessionUser } from "./storage";
import { AUTH_SIGN_IN_URL } from "./api";
import { RequestType } from "./typings";

//let sessionExpired = true;
const expiredSessionAction = (reason: any) => {
    if (reason?.response?.status === 403) {
        //toast.warning("Sorry, But you didn't have access to this page");
        /* setTimeout(() => {
            removeSessionUser()
            sessionExpired = false;
            window.location.replace(AUTH_SIGN_IN_URL);
        }, 3000) */
        window.location.replace("/403");
    } else {
        if (reason?.response?.status === 401) {
            let auth = getSessionUser();
            if (auth) {
                removeSessionUser();
                window.location.replace(AUTH_SIGN_IN_URL);
            }
        } else {
            if (reason?.response?.status === 500) {
                toast.error("Une erreur est survenue, nous travaillons pour un rétablissement à la normale au plus vite");
            }
        }
    }
};


const request = (url: string, type: RequestType, payloads: any | undefined, headers?: object) => {
    //if (sessionExpired) {
    let config: AxiosRequestConfig<any> = {};
    let auth = getSessionUser();
    if (auth) {
        config = {
            headers: {
                "Authorization": "Bearer " + auth.user.api_token,
                ...headers
            },
            onUploadProgress(progressEvent) {
                console.log(progressEvent)
            },
        }
    }
    switch (type) {
        case "delete":
            return axios.delete(url, config).catch(expiredSessionAction);
        case 'get':
            return axios.get(url, config).catch(expiredSessionAction);
        case 'patch':
            return axios.patch(url, payloads, config).catch(expiredSessionAction);
        case 'put':
            return axios.put(url, payloads, config).catch(expiredSessionAction);
        default:
            return axios.post(url, payloads, config).catch(expiredSessionAction);
    }
    /* } else {
        return null;
    } */
}
export default request;
