import {useEffect, useRef} from "react";
import { TemplateDropdownPropsInterface } from "./typings";

export default function TemplateDropdown({buttonChildren, buttonClassName = "", children, className = "", open = false, setOpen, onOpen = (() => {}), onClose = (() => {})}:TemplateDropdownPropsInterface) {
    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        function handleClickOutside(event:MouseEvent) {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                setOpen(false)
                onClose()
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, setOpen, onClose]);

    useEffect(() => {
        open ? onOpen() : onClose();
    }, [open, onOpen, onClose])
    return (
        <div className={`dropdown inline-block ${className}`} ref={ref}>
            <button type="button" className={`btn ${buttonClassName}`} onClick={() => {
                setOpen(!open)
            }}
                    aria-haspopup="true" aria-expanded="false">
                {buttonChildren}
            </button>
            <div className={`dropdown-menu dropdown-menu-end right-0 ${open ? "show" : ""}`}>
                {children}
            </div>
        </div>
    );
}
