import { LinkButton } from "../button/CustomButton";
import Dropdown from "../dropdown/Dropdown";
import BoxIcons from "../icons/BoxIcons";
import MDIcons from "../icons/MDIcons";
import { PER_PAGE_VALUE } from "../../config/global";
import { PaginationPropsInterface } from "./typings";

export default function Pagination({ itemsCount, itemCountFrom, itemCountTo, pageIndex = 1, perPage = PER_PAGE_VALUE, setPage, setPerPage }: PaginationPropsInterface) {
    const perPageItems = [
        {
            onClick: () => { setPerPage(10) },
            children: () => <>10</>
        },
        {
            onClick: () => setPerPage(25),
            children: () => <>25</>
        },
        {
            onClick: () => setPerPage(50),
            children: () => <>50</>
        },
        {
            onClick: () => setPerPage(100),
            children: () => <>100</>
        },
        {
            onClick: () => setPerPage(250),
            children: () => <>250</>
        }
    ]
    const numberOfButtons = [];
    const NUMBER_OF_PAGINATIONS_ITEMS = 3;
    const lastPage = Math.ceil(itemsCount / perPage);
    for (let i = 1; i <= lastPage; i++) {
        numberOfButtons.push(i);
    }
    if (itemsCount <= perPage || numberOfButtons.length === 0) return null;
    return (
        <div className="sm:flex sm:justify-between sm:items-center">
            <p className='text-sm text-gray-700'>
                Affichage
                <span className='ml-1 mr-1 font-bold'>{itemCountFrom}</span>
                à
                <span className='ml-1 mr-1 font-bold'> {itemCountTo} </span>
                sur
                <span className='ml-1 mr-1 font-bold'> {itemsCount} </span>
                éléments
            </p>
            <div className="sm:flex sm:mt-0 mt-4 sm:items-center">
                <Dropdown className={"bg-soft-secondary mr-2 rounded"} items={perPageItems}
                    buttonChildren={<span>Par Page: <span className={"font-bold"}>{perPage}</span> <MDIcons icon={"chevron-down"} /></span>} />
                <ul className="flex gap-2 my-5">
                    <li>
                        {pageIndex > 1 &&
                            <LinkButton
                                className={'shadow'}
                                onClick={() => setPage(pageIndex - 1)}
                                disabled={pageIndex > 1}
                            >
                                <BoxIcons icon={"left-arrow-alt"} />
                            </LinkButton>}
                    </li>
                    {
                        pageIndex > (NUMBER_OF_PAGINATIONS_ITEMS * 2) - 1 && (<>
                            <li>
                                <LinkButton
                                    onClick={() => setPage(1)}
                                >1</LinkButton>
                            </li>
                            <li>
                                <div className="flex items-center h-full w-full"><span>...</span></div>
                            </li>
                        </>)
                    }
                    {numberOfButtons.map((page, index) => {
                        if (pageIndex === page) {
                            return (
                                <li key={page}>
                                    <LinkButton
                                        onClick={() => setPage(page)}
                                        active={true}
                                    >{page}</LinkButton>
                                </li>)
                        }
                        if ((page < pageIndex + NUMBER_OF_PAGINATIONS_ITEMS) && (index + 1 > pageIndex - NUMBER_OF_PAGINATIONS_ITEMS)) {
                            return (
                                <li key={page}>
                                    <LinkButton
                                        onClick={() => setPage(page)}
                                        active={pageIndex === page}
                                    >{page}</LinkButton>
                                </li>)
                        }
                        return "" ;
                    })}
                    {
                        (pageIndex > NUMBER_OF_PAGINATIONS_ITEMS && (pageIndex + NUMBER_OF_PAGINATIONS_ITEMS < lastPage)) && (<>
                            <li>
                                <div className="flex items-center h-full w-full"><span>...</span></div>
                            </li>
                            <li>
                                <LinkButton
                                    onClick={() => setPage(lastPage)}
                                >{lastPage}</LinkButton>
                            </li>
                        </>)
                    }
                    <li>
                        {pageIndex < numberOfButtons.length &&
                            <LinkButton
                                className={'shadow'}
                                onClick={() => setPage(pageIndex + 1)}
                            > <BoxIcons icon={"right-arrow-alt"} />
                            </LinkButton>
                        }
                    </li>
                </ul>
            </div>
        </div>
    );
}
