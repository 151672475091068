import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { EditButton, AddButton } from "../components/button/CustomButton";
import CardTable from "../components/card/CardTable";
import { InputText, InputPassword, InputNumber, InputUrl } from "../components/form/Input";
import ModalForm from "../components/modal/ModalForm";
import { PER_PAGE_VALUE, FIRST_PAGE_VALUE, FormActionType } from "../config/global";
import { APP_API_URL } from "../utils/api";
import { useMutateWithInvalidateQueries } from "../utils/mutation";
import { useQueries } from "../utils/query";
import ToggleButton from "../components/button/ToggleButton";
import { Select2 } from "../components/form/Select";
import { PATTERN_IP } from "../utils/pattern";
import { BadgeCircleCheck, BadgeRounded } from "../components/badge/Badge";

const bindTypesDatas =
    [
        { text: "SMPP", value: "SMPP" },
        { text: "HTTP", value: "HTTP" }
    ]

export default function ServicePage() {
    const [pagination, setPagination] = useState({ page: FIRST_PAGE_VALUE, perPage: PER_PAGE_VALUE });
    const [currentElement, setCurrentElement] = useState(0);
    const [titleForm, setTitleForm] = useState("Ajouter un provider");
    const [actionForm, setActionForm] = useState("add");
    const [openModal, setOpenModal] = useState(false);
    let queryKey = ['services', pagination.page, pagination.perPage];
    const {
        register, setValue, watch, reset, handleSubmit, control, formState: {
            errors,
        }
    } = useForm();
    const watchShowBindType = watch("bindType", "");
    const {
        data: services,
        isLoading
    } = useQueries(APP_API_URL + `services?pageNo=${pagination.page}&pageSize=${pagination.perPage}`, queryKey);

    const {
        mutateAsync: updateProvider,
        isLoading: updateProviderIsLoading
    } = useMutateWithInvalidateQueries(APP_API_URL + `service-set-enabled/${currentElement}`, 'put', queryKey);

    const {
        mutateAsync: storeData,
        isLoading: storeDataIsLoading
    } = useMutateWithInvalidateQueries(APP_API_URL + `service-store`, 'post', queryKey)

    const {
        mutateAsync: updateData,
        isLoading: updateDataIsLoading
    } = useMutateWithInvalidateQueries(APP_API_URL + `service-update/${currentElement}`, 'put', queryKey)

    //Functions
    const handleSubmitAddForm = (values: any) => {
        storeData({
            ...values, bindType: values.bindType.value
        }).then(() => {
            reset();
            setOpenModal(false);
            toast.success('Service ajouté');
        });
    };

    const handleCancelForm = () => {
        reset();
        setCurrentElement(0);
        setOpenModal(false);
    };
    const handleAddElement = () => {
        setTitleForm("Ajouter un Service");
        setActionForm("add");
        setCurrentElement(0);
        setOpenModal(true);
        reset();
    }
    const handleEditElement = (element: any) => {
        setTitleForm("Modifier un Service");
        setActionForm("edit");
        setValue('name', element.name)
        setValue('username', element.username)
        setValue('password', element.password)
        setValue('host', element.host)
        setValue('port', element.port)
        setValue('dlrUrl', element.dlrUrl)
        setValue('bindType', bindTypesDatas.find((bind) => bind.value === element.bindType))
        setCurrentElement(element.id)
        setOpenModal(true);
    }
    const handleSubmitEditForm = (values: any) => {
        updateData({
            ...values, bindType: values.bindType.value
        }).then(() => {
            reset();
            setOpenModal(false);
            toast.success('Service modifié');
        });
    };
    const handleEditEnabled = (id: number, status: boolean) => {
        setCurrentElement(id)
        setTimeout(() => {
            const data = { isEnabled: status };
            updateProvider(data).then((response: any) => {
                reset();
                setCurrentElement(0);
                toast.success(response.data.message);
            });
        }, 1000);
    }

    //Columns Table
    const columns = [

        {
            title: "Nom",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Port",
            dataIndex: "port",
            key: "port",
        },
        {
            title: "Host",
            key: "host",
            dataIndex: "host"
        },
        {
            title: "Utilisateur",
            dataIndex: "username",
            key: "username",
        },
        {
            title: "Type de connexion",
            key: "bindType",
            className: "text-center",
            render: (element: any) => <BadgeRounded text={element.bindType} className={`${element.bindType === "SMPP" ? "badge-soft-success" : "badge-soft-warning"}`} />
        },
        {
            title: "Etat",
            key: "enabled",
            render: (element: any) => (
                <ToggleButton enabled={element.enabled} confirmed={true} textConfirmation="Confirmation de la modifcation du status du service" disabled={element.id === currentElement ? updateProviderIsLoading : false} name={element.id} onClick={() => handleEditEnabled(element.id, !element.enabled)} />
            )
        },
        {
            title: "Connecté",
            key: "bound",
            className: "text-center",
            render: (element: any) => <BadgeCircleCheck active={element.bound} />
        },
        {
            title: <span style={{ textAlign: "center" }}>#</span>,
            dataIndex: "actions",
            key: "actions",
            render: (element: any) => (
                <div className="flex justify-center gap-2">
                    <EditButton onClick={() => handleEditElement(element)} />
                </div>
            )
        }
    ];
    return (
        <>
            <CardTable extras={[<AddButton key={"add"} onClick={handleAddElement}>Ajouter</AddButton>]} columns={columns} title={"Services"}
                loading={isLoading} datas={services?.data}
                pagination={pagination} setPagination={setPagination}
                tableClassName="" />
            <ModalForm title={titleForm} isLoading={actionForm === FormActionType.ADD ? storeDataIsLoading : updateDataIsLoading}
                onClose={handleCancelForm}
                onConfirm={handleSubmit(actionForm === FormActionType.ADD ? handleSubmitAddForm : handleSubmitEditForm)}
                open={openModal}>
                <div className={"grid grid-cols-1 gap-4 sm:grid-cols-2 "}>
                    <div>
                        <InputText placeholder={"Entrer le nom"} label={"Nom"}
                            register={register} error={errors?.name}
                            name={"name"} />
                    </div>
                    <div>
                        <InputText placeholder={"Entrer le Username"} label={"Utilisateur"}
                            register={register} error={errors?.username}
                            name={"username"} />
                    </div>
                    <div>
                        <InputPassword placeholder={"Entrer le mot de passe"} label={"Mot de passse"}
                            register={register}
                            error={errors?.password}
                            name={"password"} />
                    </div>
                    <div>
                        <InputText placeholder={"Entrer l'addresse IP"} label={"L'address IP"}
                            register={register}
                            error={errors?.host}
                            name={"host"}
                            options={{
                                required: true,
                                pattern: {
                                    value: PATTERN_IP,
                                    message: "Erreur"
                                }
                            }} />
                    </div>
                    <div>
                        <InputNumber placeholder={"Entrer numero du port"} label={"Port"}
                            register={register}
                            error={errors?.port}
                            name={"port"} />
                    </div>
                    <div>
                        <Select2 control={control} placeholder={"Type de connexion"} label={"Type de connexion"}
                            register={register}
                            error={errors?.bindType}
                            name={"bindType"} textKeyItem={"text"} valueKeyItem={"value"} items={bindTypesDatas} />
                    </div>
                    {
                        watchShowBindType && watchShowBindType.value === "HTTP" && (
                            <div className="col-span-2">
                                <InputUrl placeholder={"Entrer l'url de dlr"} label={"Url de DLR"}
                                    register={register}
                                    error={errors?.dlrUrl}
                                    name={"dlrUrl"} />
                            </div>
                        )
                    }

                </div>
            </ModalForm>
        </>
    );
}
