import "./LoaderBall.css"
import "./LoaderWave.css"
import "./LoaderBallMove.css"

interface LoaderPropsInterface {
    className?: string,
    type?: 'ball' | 'wave' | 'ball-move'
}

export default function Loader({ className = "", type = 'ball' }: LoaderPropsInterface) {
    return (
        <span className={`loader ${type} ${className}`}></span>
    )
}
