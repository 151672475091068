/* import {useNavigate} from "react-router-dom";
import {useMutate} from "../../utils/mutation";
import {AUTH_API_URL} from "../../utils/api"; */

function SignUp() {
    /* const navigate = useNavigate();
    const {mutateAsync: signUp} = useMutate(AUTH_API_URL + `api/auth/sign-up`); */

    //Functions
   /*  const handleSubmitForm = (datas: any) => {
        const response = signUp(datas);
        response.then((r: any) => {
            navigate("/sign-in", {replace: true});
            //toast.success(r.data.message);
        });
    } */
    return (
        <div className="layout-default ant-layout layout-sign-up">
           
           
        </div>
    );
}

export default SignUp;