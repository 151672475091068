import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CardTable from "../components/card/CardTable";
import { Select2 } from "../components/form/Select";
import ModalForm from "../components/modal/ModalForm";
import { FIRST_PAGE_VALUE, FormActionType, PER_PAGE_VALUE } from "../config/global";
import { APP_API_URL } from "../utils/api";
import { useMutateWithInvalidateQueries } from "../utils/mutation";
import { useQueries } from "../utils/query";
import { AddButton, EditButton } from "../components/button/CustomButton";
import { toast } from "react-toastify";
import { InputText } from "../components/form/Input";
import { TRAFFICS } from "./message/configDatas";

export default function Operator() {
    const [pagination, setPagination] = useState({ page: FIRST_PAGE_VALUE, perPage: PER_PAGE_VALUE });
    const [currentElement, setCurrentElement] = useState(0);
    const [titleForm, setTitleForm] = useState("Ajouter un opérateur");
    const [actionForm, setActionForm] = useState("add");
    const [openModal, setOpenModal] = useState(false);
    const [openModalSetService, setOpenModalSetService] = useState(false);
    let queryKey = ['operators', pagination.page, pagination.perPage];
    const {
        register, setValue, reset, handleSubmit, control, formState: {
            errors
        }
    } = useForm();

    const {
        register: registerSetService, setValue: setValueSetService, reset: resetSetService, handleSubmit: handleSubmitSetService, control: controlSetService, formState: {
            errors: errorsSetService
        }
    } = useForm();

    //Queries
    const {
        data: operators,
        isLoading
    } = useQueries(APP_API_URL + `operators?pageNo=${pagination.page}&pageSize=${pagination.perPage}`, queryKey);

    const {
        data: services,
        refetch: refetchService
    } = useQueries(APP_API_URL + `service-list`, ['services-list'], {
        enabled: false
    });

    const {
        data: countries,
        refetch: refetchCountry
    } = useQueries(APP_API_URL + `country-list`, ['countries-list'], {
        enabled: false
    });


    //Mutation
    const {
        mutateAsync: storeData,
        isLoading: storeDataIsLoading
    } = useMutateWithInvalidateQueries(APP_API_URL + `operator-store`, 'post', queryKey)

    const {
        mutateAsync: updateOperatorService,
        isLoading: updateIsLoading
    } = useMutateWithInvalidateQueries(APP_API_URL + `operator-set-service/${currentElement}`, 'put', queryKey)


    //Functions

    const handleSubmitAddForm = (values: any) => {
        console.log(values)
        storeData({ ...values, traffic: values.traffic.value, serviceId: values.serviceId.id, countryId: values.countryId.id }).then(() => {
            reset();
            setOpenModal(false);
            toast.success('Opérateur ajouté');
        });
    };

    const handleSubmitEditForm = (values: any) => {
        /* updateData(values).then(() => {
            reset();
            setOpenModal(false);
            setCurrentElement(0);
            toast.success('Pays modifié');
        }); */
    };

    const handleAddElement = () => {
        setTitleForm("Ajouter un Pays");
        setActionForm("add");
        setCurrentElement(0);
        setOpenModal(true);
        reset();
        setValue('traffic', TRAFFICS[0])
    }
    const handleEditElement = (element: any) => {
        setTitleForm("Modifier un Pays");
        setActionForm("edit");
        setValue('code', element.code)
        setValue('name', element.name)
        setValue('prefix', element.prefix)
        setValue('size', element.size)
        setValue('traffic', TRAFFICS.find((traffic) => traffic.value === element.traffic))
        setCurrentElement(element.id)
        setOpenModal(true);
    }

    const handleSubmitEditService = (values: any) => {
        updateOperatorService({
            serviceId: values.serviceId.id
        }).then(() => {
            resetSetService();
            setOpenModalSetService(false);
            setCurrentElement(0);
            toast.success('Service de l\'opérateur modifié');
        });
    };
    const handleCancelForm = () => {
        resetSetService();
        setCurrentElement(0);
        setOpenModalSetService(false);
        setOpenModal(false);
    };

    const handleEditServiceOperator = (element: any) => {
        setValueSetService('serviceId', element.service)
        setCurrentElement(element.id)
        setOpenModalSetService(true);
    }

    useEffect(() => {
        refetchService()
        refetchCountry()
    }, [actionForm])


    //Columns Table
    const columns = [

        {
            title: "Nom",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Service",
            key: "service",
            render: (element: any) => (
                <div className="text-blue-800 tracking-widest w-full">
                    <EditButton className="mx-auto" value={element.service.name} onClick={() => handleEditServiceOperator(element)} />
                </div>
            )
        },
        {
            title: "Pays",
            key: "country",
            render: (element: any) => (element.country.name)

        },
        {
            title: "Traffic",
            dataIndex: "traffic",
            key: "traffic",
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
        },
        {
            title: "Regex",
            dataIndex: "regex",
            key: "regex",
        },
    ];
    return (
        <>
            <CardTable columns={columns} title={"Opérateurs"} extras={[<AddButton onClick={handleAddElement} key={"add"} />]}
                loading={isLoading} datas={operators?.data}
                pagination={pagination} setPagination={setPagination}
                tableClassName="text-left"
            />
            <ModalForm title={titleForm} isLoading={actionForm === FormActionType.ADD ? storeDataIsLoading : updateIsLoading}
                onClose={handleCancelForm}
                onConfirm={handleSubmit(actionForm === FormActionType.ADD ? handleSubmitAddForm : handleSubmitEditForm)}
                open={openModal}>
                <div className={"grid grid-cols-1 gap-4 sm:grid-cols-2 "}>
                    <div>
                        <Select2 control={control} placeholder={"Selectionner le service"} label={"Service"}
                            register={register} error={errors?.serviceId} rules={{
                                required: false
                            }}
                            name={"serviceId"} textKeyItem={"name"} valueKeyItem={"id"} items={services?.data?.data} />
                    </div>
                    <div>
                        <Select2 control={control} placeholder={"Selectionner le pays"} label={"Pays"}
                            register={register} error={errors?.countryId} rules={{
                                required: false
                            }}
                            name={"countryId"} textKeyItem={"name"} valueKeyItem={"id"} items={countries?.data?.data} />
                    </div>
                    <div>
                        <InputText placeholder={"Entrer le nom"} label={"Nom"} register={register} error={errors?.name}
                            name={"name"} />
                    </div>
                    <div>
                        <InputText placeholder={"Entrer le regex"} label={"Regex"} register={register} error={errors?.regex}
                            name={"regex"} />
                    </div>
                    <div>
                        <Select2 control={control} placeholder={"Selectionner le traffic"} label={"Trafic"}
                            register={register} error={errors?.traffic}
                            name={"traffic"} textKeyItem={"label"} valueKeyItem={"value"} items={TRAFFICS} />
                    </div>
                    <div>
                        <InputText placeholder={"Entrer la description"} label={"Description"} register={register} error={errors?.description}
                            name={"description"} />
                    </div>
                </div>
            </ModalForm>
            <ModalForm title={"Modifier le service"} isLoading={updateIsLoading}
                onClose={handleCancelForm}
                onConfirm={handleSubmitSetService(handleSubmitEditService)}
                open={openModalSetService}>
                <div>
                    <Select2 textKeyItem="name" valueKeyItem="id" register={registerSetService} control={controlSetService} items={services?.data.data} placeholder={"Services"} label={"Services"}
                        error={errorsSetService?.serviceId} name={"serviceId"}
                    />
                </div>
            </ModalForm>
        </>
    );
}
