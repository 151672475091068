import { useEffect, useState } from "react";
import LayoutContext from "./LayoutContext";
import { getSessionLayout, setSessionLayout } from "../../utils/storage";
import { LayoutType } from "../../config/global";

function LayoutProvider({ children }: { children: React.ReactNode }) {
    const [layout, setLayout] = useState(getSessionLayout());
    const changeLayout = (layout: LayoutType) => {
        document.body.setAttribute("data-layout",layout);
        setLayout(layout)
        setSessionLayout(layout)
    }
    useEffect(() => {
        changeLayout(layout)
    }, [])
    
    return <LayoutContext.Provider value={{layout: layout, changeLayout: changeLayout}}>
        {children}
    </LayoutContext.Provider>
}
export default LayoutProvider