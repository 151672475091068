import { Outlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { AUTH_SIGN_IN_URL } from "../utils/api";
import Redirect from './Redirect'

export default function RequireAuth() {
    const auth = useAuth();
    return auth && auth.user ? <Outlet /> : <Redirect to={AUTH_SIGN_IN_URL}/>;
}

