import { Switch } from '@headlessui/react'
import { ToggleButtonPropsInterface } from './typings';
import { useState } from 'react';
import { swal } from '../../config/global';

export default function ToggleButton({ enabled, name, disabled, onClick, confirmed = false, textConfirmation }: ToggleButtonPropsInterface) {
    const [isEnabled, setIsEnabled] = useState(enabled)
    return (
        <fieldset disabled={disabled} className="-py-3">
            <Switch
                name={`switch-${name}`}
                checked={enabled}
                onChange={() => {
                    if (confirmed) {
                        swal.fire({
                            text: textConfirmation,
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Confirmer',
                            cancelButtonText: "Annuler",
                            allowOutsideClick: false,
                            width: 400,
                        }).then((result: any) => {
                            if (result.isConfirmed) {
                                swal.close();
                                setIsEnabled(!isEnabled)
                                onClick()
                            }
                        })
                    } else {
                        onClick()
                        setIsEnabled(!isEnabled)
                    }
                }}
                className={`${isEnabled ? 'bg-green-400' : 'bg-gray-300'} disabled:bg-opacity-30
          relative inline-flex h-[24px] w-[48px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
                <span
                    aria-hidden="true"
                    className={`${isEnabled ? 'translate-x-3' : 'translate-x-[-8px]'} translate-y-[-5px]
            pointer-events-none inline-block h-[18px] w-[18px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                />
            </Switch>
        </fieldset>
    )
}
