import { useForm } from "react-hook-form";
import { SubmitButton } from "../../components/button/CustomButton";
import Card from "../../components/card/Card";
import { Form } from "../../components/form/Form";
import { InputPassword } from "../../components/form/Input";
import Row from "../../components/grid/Row";
import { APP_API_URL } from "../../utils/api";
import { toast } from "react-toastify";
import { useMutate } from "../../utils/mutation";

export default function ProfileResetPasswordPage() {
    const {
        mutateAsync, isLoading
    } = useMutate(APP_API_URL + `update-password`, 'put');

    const { handleSubmit, register, reset } = useForm();

    const handleUpdatePassword = (datas: any) => {
        mutateAsync(datas).then((response: any) => {
            if (response && response.status === 200) {
                toast.success('Mot de passe mise à jour');
                reset()
            }
        });
    }
    return (
        <Card title="Modifer votre mot de passe ">
            <Form onSubmit={handleSubmit(handleUpdatePassword)} loading={isLoading} >
                <Row cols={2} gap={4} className="items-center mb-4">
                    <InputPassword name="oldPassword" register={register} label="Ancien mot de passe" />
                    <InputPassword name="newPassword" register={register} label="Nouveau mot de passe" />
                </Row>
                <hr />
                <SubmitButton loading={isLoading} value={"Modifier"} className="mt-2 ml-auto" />
            </Form>
        </Card>
    )
}