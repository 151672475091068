import { Label } from "./Input";
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import { Controller } from "react-hook-form";
import { SelectPropsInterface } from "./typings";

export function SelectSimple({
    name,
    register,
    rules = { required: true },
    label = "",
    items,
    textKeyItem,
    valueKeyItem,
    placeholder = '',
    error = '',
    className = 'form-control'
}: SelectPropsInterface) {
    return (
        <>
            <Label name={name} text={label} />
            <select className={`${className} ${error && 'is-invalid'}`} defaultValue={""}
                placeholder={placeholder} {...register(name, rules)}>
                {placeholder && <option value="" disabled>{placeholder}</option>}
                {items?.map((item, key) => <option key={key} value={item[valueKeyItem]}>{item[textKeyItem]}</option>)}
            </select>
        </>);
}

export function Select2({
    name,
    control,
    items = [],
    textKeyItem,
    valueKeyItem,
    defaultValue = "",
    className = '',
    multiple = false,
    isLoading = false,
    placeholder = "",
    label = "",
    error = "",
    rules = { required: true }
}: SelectPropsInterface) {
    return <>
        <Label name={name} text={label} />
        <Controller control={control} rules={rules} render={({ field }) => {
            return (
                <Select
                    isMulti={multiple}
                    className={`${multiple ? 'basic-multi-select' : 'basic-single'} mt-[0.5rem] ${className} ${error ? 'is-invalid' : ''}`}
                    classNamePrefix="select"
                    isLoading={isLoading}
                    options={items}
                    placeholder={placeholder}
                    getOptionLabel={option => option[textKeyItem]}
                    getOptionValue={option => option[valueKeyItem]}
                    defaultValue={defaultValue}
                    isClearable={true}
                    styles={{
                        menuPortal: base => ({...base, zIndex: 99999})
                    }}
                    menuPortalTarget={document.getElementById('headlessui-portal-root')}
                    {...field}
                />
            )
        }
        } name={name} /></>;
}
export function Select2Tag({
    name,
    control,
    items = [],
    defaultValue = "",
    className = 'mt-[0.5rem]',
    multiple = false,
    isLoading = false,
    placeholder = "",
    label = "",
    error = "",
    rules = { required: true }
}: SelectPropsInterface) {
    return <>
        <Label name={name} text={label} />
        <Controller control={control} rules={rules} render={({ field }) => {
            return (
                <Creatable
                    isMulti={multiple}
                    className={`${multiple ? 'basic-multi-select' : 'basic-single'} ${className} ${error ? 'is-invalid' : ''}`}
                    classNamePrefix="select"
                    isLoading={isLoading}
                    options={items}
                    placeholder={placeholder}
                    defaultValue={defaultValue}

                    {...field}
                />
            )
        }
        } name={name} /></>;
}

