import { useAuth } from "../hooks/useAuth";

export enum UserRoles {
    ADMIN = "ADMIN",
    USER = "USER",
    SUPPORT = "SUPPORT"
}

export const CheckRole = (roles: UserRoles[]): boolean => {
    const auth = useAuth();
    return roles.find((role) => role === auth.user.roles.name) ? true : false;
}