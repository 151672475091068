import { useState } from "react";
import { APP_API_URL } from "../../utils/api";
import { ColumnInterface } from "../../components/table/typings";
import { useQueries } from "../../utils/query";
import { FIRST_PAGE_VALUE, PER_PAGE_VALUE } from "../../config/global";
import CardTable from "../../components/card/CardTable";
import moment from "moment";

export default function SenderPage() {
    const [pagination, setPagination] = useState({ page: FIRST_PAGE_VALUE, perPage: PER_PAGE_VALUE });
    const {
        data: senders,
        isLoading
    } = useQueries(APP_API_URL + `senders?pageNo=${pagination.page}&pageSize=${pagination.perPage}`, ['senders-profile', pagination.page, pagination.perPage]);

    const columns: ColumnInterface[] = [
        {
            title: "#",
            key: "index",
            render: (element: any, index: number) => <>{index + 1}</>,
        },
        {
            title: "Nom",
            dataIndex: "name",
            key: "name"
        },
        {
            title: "Crée le ",
            key: "createdAt",
            render: (element: any) => <span>{moment(element.createdAt).format('YYYY-MM-DD HH:mm:ss')}</span>
        }
    ];

    return (<CardTable columns={columns} tableClassName="text-left" title="Listes des senders" loading={isLoading} datas={senders?.data} pagination={pagination} setPagination={setPagination} />)

}