import {Link} from "react-router-dom";
import img from "../../assets/images/error-img.png"
import BoxIcons from "../../components/icons/BoxIcons";
import {LinkButton} from "../../components/button/CustomButton";
import {HOME_PAGE} from "../../config/global";

export default function ServerError() {
    return (
        <div className="account-pages my-5 pt-5">
            <div className="w-2/5 mx-auto">
                <div className="text-center mb-5">
                    <h1 className="text-2xl lg:text-9xl md:text-4xl">5<BoxIcons icon={"buoy"}
                                                                                className={"bx-spin text-primary"}/>0
                    </h1>
                    <h4 className="text-uppercase">Internal Server Error</h4>
                    <div className="mt-5 text-center">
                        <LinkButton className={"primary"}><Link to={HOME_PAGE} className="primary">Back to Home</Link></LinkButton>
                    </div>
                </div>
                <div className="flex justify-center">
                    <div>
                        <img src={img} alt="" className="img-fluid"/>
                    </div>
                </div>
            </div>
        </div>
    )
}
