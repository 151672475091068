import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { AddButton, EditButton } from "../components/button/CustomButton";
import CardTable from "../components/card/CardTable";
import { InputText } from "../components/form/Input";
import ModalForm from "../components/modal/ModalForm";
import { FIRST_PAGE_VALUE, FormActionType, PER_PAGE_VALUE } from "../config/global";
import { APP_API_URL } from "../utils/api";
import { useMutateWithInvalidateQueries } from "../utils/mutation";
import { useQueries } from "../utils/query";
import { ColumnInterface } from "../components/table/typings";

export default function RolePage() {
    const [pagination, setPagination] = useState({ page: FIRST_PAGE_VALUE, perPage: PER_PAGE_VALUE });
    const [openModal, setOpenModal] = useState(false);
    const [currentElement, setCurrentElement] = useState(0);
    const [titleForm, setTitleForm] = useState("Ajouter un pays");
    const [actionForm, setActionForm] = useState("add");
    let queryKey = ['roles', pagination.page, pagination.perPage];
    const {
        register, reset, setValue, handleSubmit, formState: {
            errors
        }
    } = useForm();

    const {
        data: roles,
        isLoading
    } = useQueries(APP_API_URL + `roles?pageNo=${pagination.page}&pageSize=${pagination.perPage}`, queryKey);

    const {
        mutateAsync: storeData,
        isLoading: storeDataIsLoading
    } = useMutateWithInvalidateQueries(APP_API_URL + `role-store`, 'post', queryKey)

    const {
        mutateAsync: updateData,
        isLoading: updateIsLoading
    } = useMutateWithInvalidateQueries(APP_API_URL + `role/${currentElement}`, 'put', queryKey)


    //Functions
    const handleSubmitAddForm = (values: any) => {
        storeData(values).then((response: any) => {
            if (response && response.data.status === "OK") {
                reset();
                toast.success('Role ajouté !');
                setOpenModal(false);
            }
        });
    };

    const handleSubmitEditForm = (values: any) => {
        updateData(values).then((response: any) => {
            if (response && response.data.status === "OK") {
                reset();
                toast.success('Role modifié');
                setOpenModal(false);
                setCurrentElement(0);
            }
        });
    };

    const handleCancelForm = () => {
        reset();
        setOpenModal(false);
    };

    const handleAddElement = () => {
        setTitleForm("Ajouter un role");
        setActionForm(FormActionType.ADD);
        setOpenModal(true);
        reset();
    }


    const handleEditElement = (element: any) => {
        setTitleForm("Modifier le role");
        setActionForm(FormActionType.EDIT);
        setValue('name', element.name)
        setCurrentElement(element.id)
        setOpenModal(true);
    }

    //Columns Table
    const columns: ColumnInterface[] = [
        {
            title: "Name",
            key: "name",
            dataIndex: "name"
        },
        {
            title: <span style={{ textAlign: "center" }}>#</span>,
            dataIndex: "actions",
            key: "actions",
            render: (element: any) => (
                <div className="flex justify-center gap-2">
                    <EditButton onClick={() => handleEditElement(element)} />
                </div>
            )
        }
    ];
    return (
        <>
            <CardTable extras={[<AddButton key={"add"} onClick={handleAddElement} />]} columns={columns} title={"Roles"}
                loading={isLoading} datas={roles?.data}
                pagination={pagination} setPagination={setPagination}
                tableClassName="text-center" />
            <ModalForm title={titleForm} isLoading={actionForm === FormActionType.ADD ? storeDataIsLoading : updateIsLoading}
                onClose={handleCancelForm}
                onConfirm={handleSubmit(actionForm === FormActionType.ADD ? handleSubmitAddForm : handleSubmitEditForm)}
                open={openModal}>
                <div className={"grid grid-cols-1"}>
                    <div>
                        <InputText placeholder={"Entrer le nom"} label={"Name"} register={register} error={errors?.name}
                            name={"name"} />
                    </div>
                </div>
            </ModalForm>
        </>
    );
}
