import { UserRoles } from "./roles"

export interface MenuInterface {
    icon: string,
    iconSolid?: boolean,
    title: string,
    link: string,
    pathname: string,
    roles: UserRoles[],
    subMenus?: MenuInterface[],
}

export const MENUS: MenuInterface[] = [
    {
        icon: "dashboard",
        iconSolid: true,
        title: "menu:dashboard",
        link: "/dashboard",
        pathname: "dashboard",
        roles: [UserRoles.ADMIN, UserRoles.SUPPORT, UserRoles.USER]
    },
    {
        icon: "message",
        iconSolid: false,
        title: "Messages",
        link: "/message",
        pathname: "message",
        roles: [UserRoles.ADMIN, UserRoles.USER, UserRoles.SUPPORT]
    },
    {
        icon: "rocket",
        title: "Senders",
        link: "/sender",
        pathname: "sender",
        roles: [UserRoles.ADMIN, UserRoles.SUPPORT]
    },
    {
        icon: "data",
        title: "Recharges",
        link: "/recharge",
        pathname: "recharge",
        roles: [UserRoles.ADMIN, UserRoles.USER, UserRoles.SUPPORT]
    },
    {
        icon: "group",
        iconSolid: false,
        title: "menu:user",
        link: "/user",
        pathname: "user",
        roles: [UserRoles.ADMIN, UserRoles.SUPPORT]
    },
    {
        icon: "server",
        title: "Sessions",
        link: "/session",
        pathname: "session",
        roles: [UserRoles.ADMIN]
    },
    {
        icon: "cog",
        iconSolid: false,
        title: "Configuration",
        link: "#",
        pathname: "#",
        roles: [UserRoles.ADMIN],
        subMenus: [
            {
                icon: "grid-alt",
                title: "Services",
                link: "/service",
                pathname: "service",
                roles: [UserRoles.ADMIN]
            },
            {
                icon: "planet",
                iconSolid: false,
                title: "menu:country",
                link: "/country",
                pathname: "country",
                roles: [UserRoles.ADMIN]
            },
            {
                icon: "broadcast",
                title: "menu:operator",
                link: "/operator",
                pathname: "operator",
                roles: [UserRoles.ADMIN]
            },
            {
                icon: "slider",
                title: "Roles",
                link: "/role",
                pathname: "role",
                roles: [UserRoles.ADMIN]
            }
        ]
    }
]