import profile from "../../assets/images/profile-img.png";
import Col from "../../components/grid/Col";
import Row from "../../components/grid/Row";
import { useAuth } from "../../hooks/useAuth";
import Avatar from "../../components/avatar/Avatar";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import BoxIcons from "../../components/icons/BoxIcons";
import { CheckRole, UserRoles } from "../../config/roles";

interface AccountMenuInterface {
  title: string,
  onClick?: () => void,
  active?: boolean,
  isVisible?: boolean
}

export default function ProfilePage() {
  const { user: auth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname.replace("/", "");

  const menusAccount: AccountMenuInterface[] = [
    {
      title: "Compte",
    },
    {
      title: "Informations du compte",
      onClick: () => navigate("/profile"),
      active: pathname === "profile"
    },
    {
      title: "Adresses IP Whitlistés",
      onClick: () => navigate("/profile/hosts"),
      active: pathname === "profile/hosts"
    },
    {
      title: "Listes des Senders",
      onClick: () => navigate("/profile/sender"),
      active: pathname === "profile/sender",
      isVisible: CheckRole([UserRoles.USER])
    },
    {
      title: "Modifier le mot de passe",
      onClick: () => navigate("/profile/reset-password"),
      active: pathname === "profile/reset-password"
    }

  ];
  return (
    <div className="md:w-3/4 w-full mx-auto">
      <div className={"grid md:grid-cols-12 grid-cols-1 gap-4"}>
        <div className={"md:col-span-4"}>
          <div className="card">
            <div className="bg-soft-primary">
              <Row cols={2}>
                <Col>
                  <div className="text-primary p-3">
                    <h5 className="text-primary">Welcome Back !</h5>
                  </div>
                </Col>
                <Col className="flex justify-end">
                  <img
                    src={profile}
                    alt=""
                    className="img-fluid h-24"
                  />
                </Col>
              </Row>
            </div>
            <div className="card-body pt-0">
              <div>
                <div className="avatar-md profile-user-wid mb-4 mx-auto">
                  <Avatar
                    title={auth.user.name}
                  />
                </div>
                <div>
                  <h5 className="mb-2"><BoxIcons icon={"user"} className="mr-2 text-primary" /> {auth.user.name}</h5>
                  <h5 className="mb-2"><BoxIcons icon={"building"} className="mr-2 text-primary" /> {auth.user.company}</h5>
                  <h5 className="mb-2"><span className="mr-2 text-primary">@</span> {auth.user.email}</h5>
                  <h5 className="mb-2"><BoxIcons icon={"phone"} className="mr-2 text-primary" /> {auth.user.phone}</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body p-4">
              <div>
                <ul className="font-semibold">
                  {
                    menusAccount.filter((menu) => menu.isVisible || menu.isVisible === undefined).map((menu: AccountMenuInterface, index: number) => {
                      if (menu.onClick === undefined) {
                        return (<li key={`menu-account-${index}`}><p className="text-muted">{menu.title}</p><hr className="my-4" /> </li>);
                      } else {
                        return (
                          <li key={`menu-account-${index}`} className={`py-2 hover:cursor-pointer ${menu.active && 'text-primary'}`} onClick={menu.onClick} >
                            <i className="mdi mdi-chevron-right mr-1" /> {menu.title}
                          </li>
                        );
                      }
                    })
                  }
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className={"md:col-span-8"}>
          <Outlet />
        </div>
      </div >
    </div>
  );
}
